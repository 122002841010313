.green-btn{
    color: #083643;
    font-weight: bolder;
    background-color: #ccdc47;
    border-color: #ccdc47;
    padding: 1rem 3rem;
}

.green-btn:hover {
    color: #083643;
    background-color: #b0d135;
    border-color: #b0d135;
}

.green-btn:active {
    background-color: #b0d135;
    border-color: #b0d135;
}

.green-btn:focus {
    color: #083643;
    background-color: #b0d135;
    border-color: #b0d135;
    box-shadow: 0 0 0 0.25rem rgb(176 209 53 / 50%);
}