
.GenerateProjection{
    padding-bottom: 0.5rem;
    display:flex;
    flex-wrap: wrap;
}
.generate-projection-footer{
    padding: 0.5rem;
}
@media (min-width: 576px) {
    .generate-projection-mdl{
            max-width: 540px;
            margin: 1.75rem auto;
        
    }
}

.GenerateProjection-body{
    text-align:center;
}

.projection-radio-button{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    color: #486d5f;
    margin: 3px;
    padding-top: 10px;
    padding-left: 2.5rem;
}
.gp-continue-btn {
    margin-top: 10px;
}
.info-mess{
    height: 30px;
    width:30px;
    margin-right: 10px;
}
