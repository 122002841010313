.CreateProject{
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.heading{
    font-weight: bolder;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
}

.description{
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}

.title{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.CreateProject .container{
    background: linear-gradient(#798d96, #b8c2c8);
    width: 70vw;
    border-radius: 20px;
    padding: 2rem 4rem;
}

.upload-btn{
    margin-top: 0.7rem;
    margin-bottom: 1rem;
}

.createProject-input{
    text-align: center;
}

.errorMessage{
    color: red;
    font-weight: bold;
}

.create-project-input{
    width: 75%;
    margin: auto;
}

.hideError{
    display: none;
}