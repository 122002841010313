.Footer {
    height: 65px;
    background-color: #083643;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Footer .btn-list {
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
}

.Footer .btn-list .blueBtnSm {
    margin: 0 5px;
}

.toolbuttongroup {
    display: block;
    /* 	border: 1px #fff solid; */
    width: 100%;
    text-align: center;
    height: auto;
    margin: 0 auto;
    padding: 8px;
}

.toolbuttongroup .toolbutton,
.viewbutton {
    background-color: #2d4f59;
    border: none;
    color: #bde5f4;
    padding-left: 34px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none;
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    cursor: pointer;
    height: auto;
    margin: 4px 4px;
    width: auto;
    text-align: center;
    border-radius: 8px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
    background-repeat: no-repeat !important;
    background-position: 7px 4px !important;
}

.addPoint {
    background-image: url(../../assets/icons/icon-point-light.svg) !important;
}

.deletePoint {
    background-image: url(../../assets/icons/icon-trash-green.svg) !important;
}

.addCamera {
    background-image: url(../../assets/icons/icon-camera-light.svg) !important;
}

.editPoint {
    background-image: url(../../assets/icons/icon-edit-light.svg) !important;
}

.pointCoordinates {
    background-image: url(../../assets/icons/icon-coordinates-light.svg) !important;
}

.viewOption {
    background-image: url(../../assets/icons/icon-view-light.svg) !important;
}

.testPoint {
    background-image: url(../../assets/icons/icon-testPoint-orange.svg) !important;
}

.editTestPoint {
    background-image: url(../../assets/icons/icon-testPoint-orange.svg) !important;
}

.generateHomography {
    background-image: url(../../assets/icons/icon-generateHomography-light.svg) !important;
}

.updateHomography {
    background-image: url(../../assets/icons/icon-generateHomography-light.svg) !important;
}

.toolbutton:hover,
.viewbutton:hover {
    color: #2d4f59;
    background-color: #bde5f4;
}

.addPoint:hover {
    background-image: url(../../assets/icons/icon-point-dark.svg) !important;
}

.deletePoint:hover {
    background-image: url(../../assets/icons/icon-trash-blue.svg) !important;
}

.addCamera:hover {
    background-image: url(../../assets/icons/icon-camera-dark.svg) !important;
}

.editPoint:hover {
    background-image: url(../../assets/icons/icon-edit-dark.svg) !important;
}

.pointCoordinates:hover {
    background-image: url(../../assets/icons/icon-coordinates-dark.svg) !important;
}

.viewOption:hover {
    background-image: url(../../assets/icons/icon-view-dark.svg) !important;
}

.testPoint:hover {
    background-image: url(../../assets/icons/icon-testPoint-blue.svg) !important;
}

.editTestPoint:hover {
    background-image: url(../../assets/icons/icon-testPoint-blue.svg) !important;
}

.generateHomography:hover {
    background-image: url(../../assets/icons/icon-generateHomography-dark.svg) !important;
}

.updateHomography:hover {
    background-image: url(../../assets/icons/icon-generateHomography-dark.svg) !important;
}

.activeTool {
    color: #083643 !important;
    background-color: #ccdc47 !important;
    box-shadow: none !important;
    padding-left: 32px !important;
    padding-right: 14px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin: 6px 6px !important;
    background-image: url(../../assets/icons/icon-active.svg) !important;
}

.activeTool:hover {
    background-image: url(../../assets/icons/icon-active.svg) !important;
}

/* Mask Button css */
#mask-dropdown {
    background-color: #2d4f59;
    border: none;
    color: #bde5f4;
    padding-left: 34px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none;
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    cursor: pointer;
    height: auto;
    margin: 4px 4px;
    width: auto;
    text-align: center;
    border-radius: 8px;
    box-shadow: 3px 3px 8px rgb(0 0 0 / 40%);
    background-image: url(../../assets/icons/icon-mask-light.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 7px 4px !important;
}

#mask-dropdown:active {
    color: #2d4f59;
    transform: translate(1px, 1px);
    box-shadow: 0px 0px 0px;
    background-image: url(../../assets/icons/icon-mask-dark.svg) !important;
}

#mask-dropdown:hover {
    color: #2d4f59;
    background-color: #bde5f4;
    background-image: url(../../assets/icons/icon-mask-dark.svg) !important;
}

.show #mask-dropdown {
    color: rgb(8, 54, 67);
    background: rgb(204, 220, 71);
    background-image: url(../../assets/icons/icon-mask-dark.svg) !important;
}

.delete-mask {
    padding-left: 32px;
    background-image: url(../../assets/icons/icon-trash-green.svg);
    background-repeat: no-repeat;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.rectangle-mask {
    padding-left: 32px;
    background-image: url(../../assets/icons/Rectangle.svg);
    background-repeat: no-repeat;
}

.square-mask {
    padding-left: 32px;
    background-image: url(../../assets/icons/square-icon.svg);
    background-repeat: no-repeat;
}

#mask-dropdown+.dropdown-menu {
    background-color: #2c4f58;
    overflow-y: initial;
    width: 146px;
    padding: 12px 10.5px 10px 10px;
    position: absolute !important;
    bottom: 20px !important;
}

.mask-text {
    font-family: 'Quicksand', sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e4e4e4;
}

.dropup {
    display: inline;
}