.edit-scene-body{
    text-align: center;
}

.edit-scene-body h2{
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 0;
}

.edit-scene-body textarea{
    margin-bottom: 2rem;
}

.edit-scene-body p{
    margin-bottom: 0.5rem;
}

.edit-scene-body .btn-list{
    margin-top: 3rem;
}

.modal-content{
    border: 0;
}