.ImageMapping{
    /* padding: 4rem 10vw; */
    width: 90vw;
    margin: auto;
    padding: 4rem 0;
}

.image-outer-box{
    display: flex;
    background: linear-gradient(#798d96, #b8c2c8);
    padding: 2rem;
    border-radius: 20px;
}

.image-inner-box{
    width: 50%;
    margin: 0px 10px;
}

.image-inner-box-header{
    display: flex;
    justify-content: space-between;
}

.image-mapping-box{
    border: 1px solid black;
    /* min-height: 600px; */
	height: 70vh;
}

.scene-description{
    color: white;
    font-weight: 100;
}

.image-inner-box-header h3{
    font-size: 18px;
    margin-bottom: 0;
}

.scenes-tags{
    text-align: center;
}

.scenes-tags-heading{
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.scene-box{
    display: flex;
    justify-content: center;
}

.scene-tag{
    background: #bde5f4;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    margin: 0 0.6rem;
    font-weight: 500;
    border: none;
}

.scene-tag:hover{
    background: #83d3f0;
    cursor: pointer;
}

.scene-tag:active{
    box-shadow: 0 0 0 0.25rem rgb(105 194 215 / 50%);
}

.image-container {
    width: 100%;
}

.container-sceneTitle
{
	font-size: 28px;
}

.sceneTitle
{
	font-weight: bold;
	color: #313131;
	display: inline-block;
	text-transform: uppercase;
    margin-right: 8px;
}

.sceneName
{
	font-family: 'Quicksand', sans-serif;
	font-weight: 600;
	color: #313131;
	/* text-shadow: 1px 1px 0px #083643; */
	display: inline-block;
}

.editScene
{
	display: inline-block;
	background-image: url(../../assets/icons/icon-editScene-blue.svg)!important;
	background-repeat: no-repeat !important;
	background-position: 4px center !important;
	background-size: auto 18px;
	height: 30px;
	width: 30px;
	top: 6px;
	position: relative;
}

.editScene:hover{
	background-image: url(../../assets/icons/icon-editScene-green.svg)!important;
}

.viewbuttongroup
{
	text-align: center;
	height: auto;
}

.viewBTN
{
	background-color: #2d4f59;
	border: none;
	color: #bde5f4;
	padding-left: 40px;
	padding-right: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	text-decoration: none;
	display: inline-block;
	font-family: 'Quicksand', sans-serif;
	font-weight: 500;
	font-size: 9px;
	line-height: 9px;
	text-transform: uppercase;
	cursor: pointer;
	height: auto;
    margin-left: 4px;
	width: auto;
	text-align: center;
	border-radius: 8px 0px 0px 0px;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
	background-repeat: no-repeat !important;
	background-position: 10px 6px !important;
	background-size: auto 22px;
}

.zoomIn { background-image: url(../../assets/icons/icon-viewZoomIn-light.svg)!important; }
.zoomOut { background-image: url(../../assets/icons/icon-viewZoomOut-light.svg)!important; }
.position { background-image: url(../../assets/icons/icon-viewPosition-light.svg)!important; }

.viewBTN:hover
{
	color: #2d4f59;
	background-color: #bde5f4;
}

.viewBTN:active
{
	transform: translate(1px, 1px);
	box-shadow: 0px 0px 0px;
	background-color: #ccdc47!important;
}

.zoomIn:hover { background-image: url(../../assets/icons/icon-viewZoomIn-dark.svg)!important; }
.zoomOut:hover { background-image: url(../../assets/icons/icon-viewZoomOut-dark.svg)!important; }
.position:hover { background-image: url(../../assets/icons/icon-viewPosition-dark.svg)!important; }

.BTN-green-delete{
	background-color: #ccdc47;
	border: none;
	text-decoration: none;
	font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
	color: #083643;
	padding: 25px 40px 25px 40px;
	text-align: center;
	font-size: 20px;
	border-radius: 12px;
	box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.2);
	width: auto;
	height: auto;
	margin: 5px;
	display: inline-block;
	text-transform: uppercase;
	margin-top: 0px;
	cursor: pointer;
}

.BTN-green-delete
{
	background-image: url(../../assets/icons/icon-trash-blue.svg)!important;
	background-repeat: no-repeat !important;
	background-position: 40px center !important;
	padding-left: 70px;
}

.BTN-green-delete:hover { background-image: url(../../assets/icons/icon-trash-green.svg)!important; }

.BTN-green:hover, .BTN-green-delete:hover
{
	background-color: #2d4f59;
	color: #b0d135;
}

.deletePointBox{
	margin-top: 1rem;
	text-align: center;
}

.BTN-green-delete:active
{
	color: #b0d135;
	transform: translate(1px, 1px);
	box-shadow: 0px 0px 0px;
}

.upper-canvas{
	cursor: none !important;
}

.scenNotes {
	font-size: 20px;
	font-weight: 500;
	color:#161616;
}

.cursor{
	position: absolute;
	transform: translate(-50%, -50%);
	pointer-events: none;
	transition: all 0.2 ease;
	transition-property: background, border, transform;
	transform-origin: 150% 150%;
	z-index: 999;
}

.cursor {
	display: none;
}

@media only screen and (max-width: 1300px) {
	.image-outer-box {
		padding: 1rem;
	}

	.image-inner-box-header h3 {
		font-size: 14px;
	}

	.viewBTN {
		font-size: 8px;
		padding-right: 8px;
		padding-left: 36px;
	}
}

@media only screen and (max-width: 1500px) {
	.ImageMapping {
		padding: 2rem 0;
		padding-bottom: 8rem;
		width: 95vw;
	}

	.image-inner-box {
		width: 48%;
	}
}