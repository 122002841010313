.saveWithoutHomography{
    padding: 20px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #486d5f;
    background-color: #bde5f4;
    border: 1px #486d5f solid;
    text-align: center;
    width: 580px;
    height: auto;
    margin: 0px auto;
    box-shadow: 3px 3px 8px rgb(0 0 0 / 10%);
    position: relative;
    display: block;
}

.saveWithoutHomography p{
    font-family: 'Quicksand', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #486d5f;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
    margin: 1rem 0;
}

.saveWithoutHomography img{
    width: 2rem;
}