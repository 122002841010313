.App{
    margin-top: 60px;
    margin-bottom: 50px;
}
.rp-image{
    margin-left: 5px !important;
    margin-top: 10px;
}
.fileUploader .fileContainer p{
    display:none !important;
}
.fileContainer {
    background: none !important;
}
.fileContainer .uploadPicturesWrapper {
    max-height: 350px !important;
    overflow: auto;
}
.fileContainer .uploadPicturesWrapper::-webkit-scrollbar {
    width: 6px;
    background: #fff;
    border-radius: 12px;
}
.fileContainer .uploadPicturesWrapper::-webkit-scrollbar-thumb {
    background: #b0d135; 
  }