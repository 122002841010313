.toast{
  position: fixed;
  right: 1vw;
  z-index: 100;
}

.toast-header{
  font-size: 1rem;
  justify-content: space-between;
  background-color: white;
}

.toast-body{
  padding: 0.6rem;
  background-color: white;
  display: flex;
  min-width: 270px;
}

.toast-header button.close, .toast-header button.close:hover{
  color: black;
}

.loader {
  margin: 0 1rem;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
  
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alert-error {
  margin-bottom: 1rem;
}

.alert-error p{
  margin-bottom: 0;
}