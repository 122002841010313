html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
	font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#798d96, #b8c2c8);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color: none;
  text-decoration: none;
}

a:hover {
  color: none;
  text-decoration: none;
}

.fileContainer .deleteImage {
  background: #728c3a !important;
}