.generateHomographyPopup{
    padding: 20px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #486d5f;
    background-color: #bde5f4;
    border: 1px #486d5f solid;
    text-align: center;
    width: 580px;
    height: auto;
    margin: 0px auto;
    box-shadow: 3px 3px 8px rgb(0 0 0 / 10%);
    position: relative;
    display: block;
}

.generateHomographyPopup p{
    font-family: 'Quicksand', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #486d5f;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
    margin: 1rem 0;
}

.BTN-blue-overlay{
    background-color: #2d4f59;
    border: none;
    text-decoration: none;
    font-family: 'Quicksand', sans-serif;
    color: #bde5f4;
    padding: 12px 19px 12px 19px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    border-radius: 12px;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    width: auto;
    display: inline-block;
    text-transform: uppercase;
    margin: 4px;
    cursor: pointer;
}



.homography-loader {
    margin: 0 1rem;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-right: auto;
    margin-left: auto;
  }

.projection-error {
  color: red !important;
  font-weight: bold !important;
}
    
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
    
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }