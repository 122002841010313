.LoadFloorPlan{
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.LoadFloorPlan .container{
    background: linear-gradient(#798d96, #b8c2c8);
    width: 70vw;
    border-radius: 20px;
    padding: 2rem 4rem;
}

.heading{
    font-weight: bolder;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
}

.LoadFloorPlan .container .upload{
    margin: 1rem 0;
}

.json-file-uploader {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.navigation-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation-buttons div{
    margin: 0 1rem;
}

/* .uploadPicture{
    width: 100px;
    padding: 10px;
} */

.chooseFileButton_loaddata {
    padding: 6px 23px;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: rgb(13, 110, 253);
    border-color: rgb(13, 110, 253);
}
.uploadPicturesWrapper_inner_div{
    position: relative;
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-wrap: wrap; 
    width: 100%;
}

.LoadFloorPlan > p{
    width: 70vw;
    margin: 16px auto;
}

.mb-0{
    margin-bottom: 0;
}