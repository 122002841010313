.LoadAddScene{
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.LoadAddScene .container{
    background: linear-gradient(#798d96, #b8c2c8);
    width: 70vw;
    border-radius: 20px;
    padding: 2rem 4rem;
}

.heading{
    font-weight: bolder;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
}