.Header {
    height: 65px;
    background-color: #083643;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    border: 1px #B35C33 dotted;
    padding-right: 2%;
    padding-left: 2%;
}

.flex-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-container>div {
    text-align: center;
    font-size: 30px;
    color: white;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
}

.calibration-logo {
    height: 83px;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.pointer-logo {
    cursor: pointer;
}

.logo-heading {
    color: #b0d135;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo-heading h1 {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
}

.logo-heading h4 {
    font-size: 10px;
    line-height: 10px;
    margin-top: 3px;
    font-weight: 100;
}

.project-info {
    display: flex;
    color: #bde5f4 !important;
    font-size: 16px !important;
}

.project-info .client-name {
    padding-right: 10px;
    border-right: 3px solid #ccdc47;
}

.project-info .project-name {
    margin-left: 10px;
    font-weight: 700;
}

.dropdown-menu {
    overflow-y: scroll;
    max-height: 40vh;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #486d5f;
    background-color: #bde5f4;
    border: 1px #486d5f solid;
    text-align: center;
    margin: auto;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
}

#button-scene-library {
    background-color: #2d4f59;
    border: none;
    color: #bde5f4;
    padding: 14px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.2);
}

#button-scene-library:active {
    color: #2d4f59;
    transform: translate(1px, 1px);
    /* translate X position, y position */
    box-shadow: 0px 0px 0px;
}

.show #button-scene-library {
    color: rgb(8, 54, 67);
    background: rgb(204, 220, 71);
}

.addScene-item {
    font-size: 18px;
    font-weight: 700;
    color: #486d5f;
    padding: 20px;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px #486d5f solid;
    margin: 8px auto 8px auto;
    width: fit-content;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
    color: none !important;
    text-decoration: none !important;
    background-color: none !important;
}

/* Save Project button */
#saveProject {
    background-color: #2d4f59;
    border: none;
    color: #bde5f4;
    padding: 14px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 3px 3px 14px rgb(0 0 0 / 20%);
    background-image: url(../../assets/icons/icon-save-light.svg) !important;
    padding-left: 37px;
}

#saveProject:hover {
    color: #2d4f59;
    background-color: #bde5f4;
}

#saveProject:hover {
    background-image: url(../../assets/icons/icon-save-dark.svg) !important;
}

/* width */
.dropdown-menu::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .dropdown-menu::-webkit-scrollbar-thumb {
    background: #bbbfc1;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }