.AddScene {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.heading {
    font-weight: bolder;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
}

.description {
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.AddScene .container {
    background: linear-gradient(#798d96, #b8c2c8);
    padding: 2rem 4rem;
    width: 70vw;
    border-radius: 20px;
}

.btn-list {
    display: flex;
    justify-content: space-evenly;
    margin-top: 0.7rem;
    margin-bottom: 2rem;
}

.btn-list .fileUploader {
    margin-right: 2px;
    margin-left: 2px;
}

.image-pill {
    margin: 5px 4px;
    padding: 10px 14px 9px 19px;
    border-radius: 30px;
    box-shadow: 0 1px 0 0 rgba(105, 105, 105, 0.16);
    background-color: #fff;
    display: flex;
}

.image-pill-text{
  margin: 3px 7px 3px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #313131;
}

.image-pill img{
    width: 20px;
    height: 20px;
}

.image-pill img:hover{
    cursor: pointer;
}