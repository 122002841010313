.modal-content{
    border: 1px solid black;
    border-top: 0;
}

.modal-header{
    color: white;
    background: #083643;
}

.modal-title{
    font-weight: 100;
}

.update-btn{
    text-align: center;
}

.modal-body{
    background: #bde5f4
}

.modal-body h2{
    font-weight: 100;
    font-size: 1.4rem;
}

.modal-body .outer-box{
    display: flex;
    margin-bottom: 2rem;
}

.inner-box{
    width: 50%;
    text-align: center;
    margin: 0 5px;
}

table, th, td {
    border: 0.5px solid black;
    border-collapse: collapse;
    font-weight: 500;
}

.close, .close:hover {
    color: white;
}

.viewCoordInput{
    text-align: center;
    background-color: #bde5f4;
    border: none;
}

.error-h3{
    font-family: 'Quicksand', sans-serif;
	font-weight: 700;
	font-size: 24px;
	line-height: 22px;
	color: #486d5f;
	text-align: center;
	margin-bottom: 1.5rem;
	padding-top: 10px;
    display: flex;
    justify-content: center;
    padding-top: 0;
}

.error-h3 img{
    margin-right: 6px;
}


@media only screen and (max-width: 1500px) {
    .modal-backdrop {
        width: 100%;
        height: 100%;
    }

    .modal-xl{
        max-width: 90%;
    }
  }

.errorMessage-pointCoordinates{
    font-family: 'Quicksand', sans-serif;
	font-weight: 600;
    font-size: 16px;
	line-height: 22px;
	color: red;
	text-align: center;
	margin-bottom: 1.5rem;
	padding-top: 10px;
    display: flex;
    justify-content: center;
    padding-top: 0;
}

.errorMessage-pointCoordinates img{
    margin-right: 6px;
}
