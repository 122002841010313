.Home {
    text-align: center;
    height: 80vh;
    display: flex;
}

.intro-box {
    margin: auto;
    padding: 2rem;
}

.intro-box>h2 {
    font-size: 39px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.intro-box>p {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-start;
}

a,
a:hover {
    color: none;
    text-decoration: none;
}

.inner-intro-box {
    width: 950px;
    height: 353px;
    margin-top: 39px;
    margin-left: auto;
    margin-right: auto;
    padding: 47px 49px 49px;
    border-radius: 10px;
    background-color: #dff4fb;
}


.inner-intro-box>p {
    width: 852px;
    height: 101px;
    margin: 0 auto 50px auto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.home-green-btn {
    width: 350px;
    height: 106px;
    padding: 28px 17px 24px;
    border-radius: 10px;
    box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #b0d135;
}

.home-green-btn:hover {
    background-color: #9bb92f;
    cursor: pointer;
}

img.sync {
    width: 28px;
    height: 28px;
    margin: 1px 19px 11px 10px;
    object-fit: contain;
}

img.compliant {
    width: 28px;
    height: 28px;
    margin: 0 7px 15px 0;
    object-fit: contain;
}

.heading-in-btn {
    width: 188px;
    height: 28px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f1f1f;
}

.sub-heading-in-btn {
    width: 316px;
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.mr-70 {
    margin-right: 70px;
}